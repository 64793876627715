.questionBankOrderList .itemLabel {
  min-width: 6rem;
}
.orderDetail .order_stu {
  padding: 10px;
  background-color: #ffffcc;
  border: 1px solid #ffcc00;
  color: #ff3300;
}
.orderDetail .order_form div.df {
  padding: 8px 0;
}
.orderDetail .order_form div.df span:first-child {
  width: 6rem;
  text-align: right;
  margin-right: 8px;
}
