























































































































































































































































































































































































































































































































































































































.questionBankOrderList {
  .itemLabel {
    min-width: 6rem;
  }
}
.orderDetail {
  .order_stu {
    padding: 10px;
    background-color: rgb(255, 255, 204);
    border: 1px solid rgb(255, 204, 0);
    color: rgb(255, 51, 0);
  }
  .order_form {
    div.df {
      padding: 8px 0;
      span:first-child {
        width: 6rem;
        text-align: right;
        margin-right: 8px;
      }
    }
  }
}
